import React, { memo } from 'react';
import { Text, TextProps } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import styled from 'styled-components';

const StyledText = styled(Text)`
  white-space: pre-wrap;
`;
export const sanitizeText = (textString: string) => {
  if (textString) {
    return textString
      .replace(/&#8203;/gi, String.fromCharCode(8203))
      .replace(/&zwnj;/gi, String.fromCharCode(8204))
      .replace(/&nbsp;/gi, String.fromCharCode(160))
      .replace(/&#8209;/gi, String.fromCharCode(8209))
      .replace(/<br>/gi, String.fromCharCode(10));
  }
  return '';
};

export interface TextPropsExtended extends TextProps {
  text?: string;
}

export default memo(function RichText({ text, ...props }: TextPropsExtended) {
  const convertRichText = (richText: string) => {
    const sanitizedText = sanitizeText(richText);

    return renderTextWithFootnotesReferencesV2(sanitizedText);
  };
  return <StyledText {...props}>{convertRichText(text as string)}</StyledText>;
});
