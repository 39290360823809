import type { Theme } from '@audi/audi-ui-react';
import type {
  NumbersItem,
  TextItem,
  EditorialColumnsFACE,
  ModuleNumbersMode,
  ModuleTextMode,
} from '../../types';
import APP_ID from './environment';
import { DEMO_CONTENT_NUMBERS_MODE, DEMO_CONTENT_TEXT_MODE } from '../content/content';

export const isDarkTheme = (theme: Theme): boolean => theme.name === 'Audi Dark Theme';

export const APP_NAME = APP_ID.replace('oneaudi-feature-app-', '').replace(/-/g, ' ');

enum NumberOfComponents {
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
}

const getNumberOfComponentsPerRow: { [index: string]: number } = {
  [NumberOfComponents.TWO]: 2,
  [NumberOfComponents.THREE]: 3,
  [NumberOfComponents.FOUR]: 2,
  [NumberOfComponents.FIVE]: 3,
  [NumberOfComponents.SIX]: 3,
  [NumberOfComponents.SEVEN]: 3,
  [NumberOfComponents.EIGHT]: 2,
  [NumberOfComponents.NINE]: 3,
};

export const groupComponents = ({ components }: ModuleTextMode | ModuleNumbersMode) => {
  if (!components) return null;
  const offset = getNumberOfComponentsPerRow[components.length];
  let count = 0;
  const final: (TextItem[] | NumbersItem[])[] = [];
  let tempArray: (TextItem | NumbersItem)[] = [];

  components.forEach((comp) => {
    if (count < offset) {
      tempArray.push(comp);
      count += 1;
    } else {
      count = 1;
      final.push(tempArray);
      tempArray = [comp];
    }
  });
  final.push(tempArray);

  return final;
};

function readQueryParameter<T, F = T>(parameter: string, fallback: F): T | F {
  if (typeof window === 'undefined') return fallback;

  const query = new URLSearchParams(window.location.search);

  const value = query.get(parameter);
  if (value === null) return fallback;

  try {
    return JSON.parse(value) as T;
  } catch {
    return fallback;
  }
}

export const mode = readQueryParameter<string, undefined>('mode', undefined);

export const createDemoContent = (localquery: string | undefined): EditorialColumnsFACE => {
  if (localquery === undefined) return DEMO_CONTENT_TEXT_MODE;

  if (localquery !== 'text' && localquery !== 'numbers') {
    throw new Error('The provided mode is not supported');
  }

  if (localquery === 'text') return DEMO_CONTENT_TEXT_MODE;

  return DEMO_CONTENT_NUMBERS_MODE;
};

export const FA_LOCATOR_ID = `fa-editorial-colmuns`;

export const FA_LOCATOR = `[data-test-id=${FA_LOCATOR_ID}]`;

export function getInstanceIndexNo(ref: React.MutableRefObject<null>): number {
  const quoteElements = document.querySelectorAll(FA_LOCATOR);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < quoteElements.length; i++) {
    if (quoteElements[i] === ref.current) {
      return i;
    }
  }

  return 0;
}
