import styled from 'styled-components';
import { Theme } from '@audi/audi-ui-react';
import DesignTokens from '@audi/audi-ui-design-tokens';
import { ComponentContainerOptions, NumberAndHeadlineOption } from '../../types';
import { isDarkTheme } from '../utils/utils';
import { animateContainer } from './EditorialColumns.style';

export const Row = styled.div`
  display: flex;
  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.xs}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.m - 1}px) {
    flex-direction: column;

    &:last-child div:last-child {
      border: none;
    }
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    justify-content: center;
    ${({ flexibleWidth, theme }: { flexibleWidth: boolean; theme: Theme }) =>
      !flexibleWidth &&
      `
      > div {
          border-right: 1px solid ${
            isDarkTheme(theme) ? DesignTokens.COLOR_BASE_GREY_70 : DesignTokens.COLOR_BASE_GREY_30
          };
        }

      `}
    div:last-child {
      border-right: none;
    }
    &:not(:last-child) {
      ${({ theme }: { theme: Theme }) =>
        `
      margin-block-end: var(${theme.responsive?.spacing.xxl});
      `}
    }
  }
`;

export const ComponentsContainer = styled.div`
  width: 100%;
  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ theme }: { theme: Theme }) =>
      `
    margin-bottom:  var(${theme.responsive?.spacing.xl});
    `}
  }
`;

export const ComponentContainer = styled.div<ComponentContainerOptions>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignmentNumbers || 'center'};
  justify-content: flex-start;
  animation: ${animateContainer} cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.75s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  opacity: 0;
  animation-delay: ${(props) => props.delay};
  animation-play-state: ${(props) => (props.inView ? 'running' : 'paused')};
  text-align: ${(props) => props.alignmentNumbers || 'center'};
  #margin-inline-start-left {
    margin-inline-start: 0px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.xs}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.m - 1}px) {
    ${({ theme }: { theme: Theme }) =>
      `
      animation-play-state: running;
      opacity: 1;
      border-bottom: 1px solid ${
        isDarkTheme(theme) ? DesignTokens.COLOR_BASE_GREY_70 : DesignTokens.COLOR_BASE_GREY_30
      };
      padding-inline-start: var(${theme.responsive?.spacing.xxxl});
      padding-inline-end: var(${theme.responsive?.spacing.xxxl});
      padding-block-start:  var(${theme.responsive?.spacing.xxxl});
      padding-block-end: var(${theme.responsive?.spacing.xxxl});
    `}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ theme, flexibleWidth }: { theme: Theme; flexibleWidth: boolean }) =>
      `
      ${flexibleWidth === true ? '' : 'width: 30%'};
      padding-inline-start: var(${theme.responsive?.spacing.xl});
      padding-inline-end: var(${theme.responsive?.spacing.xl});
      padding-block-start:  var(${theme.responsive?.spacing.xl});
      padding-block-end: var(${theme.responsive?.spacing.xl});
      `}
  }
`;

export const NumberAndHeadline = styled.div<NumberAndHeadlineOption>`
  ${({ theme }: { theme: Theme }) =>
    `
    font-family: var(${theme.responsive?.typography.copy1.fontFamily});
    font-size: var(${theme.responsive?.typography.copy1.fontSize});
    `}
  text-align: ${(props) => props.alignmentNumbers || 'center'};
  p {
    display: inline-block;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xs}px) {
    ${({ theme }: { theme: Theme }) =>
      `
    margin-block-start:  var(${theme.responsive?.spacing.m});
    margin-block-end: var(${theme.responsive?.spacing.m});
    `}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ theme }: { theme: Theme }) =>
      `
    margin-block-start:  var(${theme.responsive?.spacing.l});
    margin-block-end: var(${theme.responsive?.spacing.l});
    `}
  }
`;
