/* eslint-disable camelcase, @typescript-eslint/ban-ts-comment */
import { ContentFragment, InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';
import { EditorialColumnsFACE, HeadingTag, NumbersItem, TextItem, ThemeType } from '../../types';

type TextItemHeadless = {
  title: string;
  description: string;
  cta_variant: 'text' | 'secondary';
  cta_label: string;
  cta_url: string;
  cta_openInTabOrLayer: 'page' | 'tab' | 'layer';
};

type NumberItemHeadless = {
  number?: string;
  title?: string;
  unit?: string;
  description?: string;
  cta_label?: string;
  cta_url?: string;
  cta_variant: 'text';
  cta_openInTabOrLayer?: 'page' | 'tab' | 'layer';
};

function isFalconContent(content: unknown): content is FalconContent {
  return Boolean(isContentFragment(content));
}
type components = ContentFragment &
  (
    | {
        fields: TextItemHeadless;
      }
    | {
        fields: NumberItemHeadless;
      }
  )[];

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    textAlignment: 'left' | 'center';
    alignmentNumbers: 'center' | 'left';
    flexibleWidth: boolean;
    caeIdentifierOption?: string[];
    disclaimersOption?: string[];
    themeOption_theme?: ThemeType;
    moduleContent: ContentFragment & {
      fields: {
        components: components;
      };
    };
    headingOption_headingTag: HeadingTag;
    headingOption_heading: string;
  };
}

export interface FaceContent extends EditorialColumnsFACE {
  __type?: 'editor.json';
}

export function normalizeContent(content: FalconContent | FaceContent): FaceContent {
  if (content.__type !== 'aem-headless') {
    return content;
  }
  if (
    (content.__type === 'aem-headless' && !isFalconContent(content)) ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    !content.fields.moduleContent
  ) {
    throw new InvalidContentError('Content is invalid.');
  }
  if (!content || Object.keys(content).length === 0) {
    // eslint-disable-next-line no-console
    console.warn('No Editor content found for Buttons FA');
    return {} as EditorialColumnsFACE;
  }

  const contentType = content.fields.moduleContent.model?.title.includes('Text Mode')
    ? 'text'
    : 'numbers';
  const components: (NumbersItem | TextItem)[] = [];
  content.fields.moduleContent.fields.components.forEach(({ fields: item }) => {
    if (contentType === 'text') {
      components.push({
        cta: {
          variant: (item as TextItemHeadless).cta_variant,
          label: (item as TextItemHeadless).cta_label,
          url: (item as TextItemHeadless).cta_url,
          openInTabOrLayer: (item as TextItemHeadless).cta_openInTabOrLayer,
        },
        title: item.title,
        description: item.description,
      });
    } else {
      components.push({
        number: (item as NumberItemHeadless).number as string,
        unit: (item as NumberItemHeadless).unit,
        title: item.title,
        description: item.description,
        cta: {
          variant: 'text',
          label: (item as NumberItemHeadless).cta_label as string,
          url: (item as NumberItemHeadless).cta_url as string,
          openInTabOrLayer: (item as NumberItemHeadless)?.cta_openInTabOrLayer,
        },
      });
    }
  });
  return {
    textAlignment: content.fields.textAlignment,
    alignmentNumbers: content.fields.alignmentNumbers,
    flexibleWidth: content.fields.flexibleWidth,
    caeIdentifierOption: content.fields.caeIdentifierOption,
    disclaimersOption: content.fields.disclaimersOption,
    themeOption: {
      theme: content.fields.themeOption_theme,
    },
    headingOption: {
      heading: content.fields.headingOption_heading,
      headingTag: content.fields.headingOption_headingTag,
    },
    moduleContent: {
      type: contentType,
      components,
    },
  } as EditorialColumnsFACE;
}
