(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@audi/audi-ui-react"), require("@feature-hub/react"), require("react"), require("react-dom"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["@audi/audi-ui-react", "@feature-hub/react", "react", "react-dom", "styled-components"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@audi/audi-ui-react"), require("@feature-hub/react"), require("react"), require("react-dom"), require("styled-components")) : factory(root["@audi/audi-ui-react"], root["@feature-hub/react"], root["react"], root["react-dom"], root["styled-components"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__28006__, __WEBPACK_EXTERNAL_MODULE__39544__, __WEBPACK_EXTERNAL_MODULE__39155__, __WEBPACK_EXTERNAL_MODULE__69514__, __WEBPACK_EXTERNAL_MODULE__88694__) => {
return 