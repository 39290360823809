import React, { FC, useCallback, useContext } from 'react';
import { Button, Text } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { UeElement, UeReference } from '@oneaudi/falcon-tools';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { LayerContentHTML } from '@oneaudi/fa-one-layer/dist/utils';
import { HeadingOption, NumbersItem } from '../../types';
import { NumbersHeadlineContainer } from './EditorialColumns.style';
import {
  ComponentContainer,
  ComponentsContainer,
  NumberAndHeadline,
  Row,
} from './NumbersMode.style';
import { setHeadlessUrl } from '../utils/setHeadlessUrl';
import { Context } from '../Context';

import RichText from './RichTextNumbersMode';

export interface NumbersModeProps {
  animationRef: React.MutableRefObject<null>;
  components: NumbersItem[][];
  inView: boolean;
  headingOption?: HeadingOption;
  alignmentNumbers: 'left' | 'center';
  flexibleWidth: boolean;
}

export const NumbersMode: FC<NumbersModeProps> = ({
  animationRef,
  components,
  inView,
  headingOption,
  alignmentNumbers,
  flexibleWidth,
}) => {
  const { layerManager } = useContext(Context);

  const handleOpenInLayer = useCallback((url: string) => {
    // eslint-disable-next-line react/destructuring-assignment
    layerManager?.openFocusLayer(
      () => <LayerContentHTML enableDeepLinking url={`${setHeadlessUrl(url)}`} />,
      {},
      {
        userCloseable: true,
        size: FocusLayerSizeV2.A,
      },
    );
  }, []);
  return (
    <>
      {headingOption && (
        <NumbersHeadlineContainer data-testid="text-headline-container">
          <Text
            data-testid="numbers-headline"
            variant="order2"
            as={headingOption.headingTag || 'h2'}
          >
            <UeElement type="text" property="headingOption_heading" label="Headline">
              {renderTextWithFootnotesReferencesV2(headingOption.heading)}
            </UeElement>
          </Text>
        </NumbersHeadlineContainer>
      )}

      <ComponentsContainer data-testid="numbers-components-container" ref={animationRef}>
        {components.map((row, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row flexibleWidth={flexibleWidth} data-testid="numbers-components-row" key={`row-${i}`}>
            {row.map((x: NumbersItem, elementIndex) => (
              <ComponentContainer
                flexibleWidth={flexibleWidth}
                key={`ge-${x.number}`}
                data-testid="numbers-component"
                delay={`${elementIndex * 0.3}s`}
                inView={inView}
                alignmentNumbers={alignmentNumbers || 'center'}
              >
                <UeReference propertyPath={`moduleContent.components.${i * 3 + elementIndex}`}>
                  {x.title && (
                    <UeElement type="text" property="title" label="Title">
                      <RichText key={`ge-title-${x.number}`} text={x.title} />
                    </UeElement>
                  )}

                  <NumberAndHeadline
                    alignmentNumbers={alignmentNumbers || 'center'}
                    key={`ge-number-and-headline-${x.number}`}
                  >
                    <UeElement type="text" property="number" label="Number">
                      <RichText
                        data-testid="numbers-component-number"
                        key={`ge-number-${x.number}`}
                        text={x.number}
                        variant="order1"
                        weight="normal"
                      />
                    </UeElement>

                    <UeElement type="text" property="unit" label="Unit" as="span">
                      <RichText
                        id={alignmentNumbers === 'left' ? 'margin-inline-start-left' : ''}
                        key={`ge-unit-${x.number}`}
                        variant="copy1"
                        spaceInlineStart="xs"
                        text={x.unit as string}
                      />
                    </UeElement>
                  </NumberAndHeadline>

                  {x.description && (
                    <UeElement type="text" property="description" label="Description" as="p">
                      <RichText variant="copy1" text={x.description} />
                    </UeElement>
                  )}
                  {x.cta && x.cta.label && x.cta.url && (
                    <Button
                      newWindow={x.cta.openInTabOrLayer === 'tab'}
                      stretch
                      variant={x.cta.variant}
                      href={x.cta.openInTabOrLayer === 'layer' ? undefined : x.cta.url}
                      onClick={() => {
                        if (x.cta?.openInTabOrLayer === 'layer') {
                          handleOpenInLayer(x.cta.url);
                        }
                      }}
                      size="medium"
                      data-testid="text-component-button"
                      spaceStackStart="l"
                    >
                      <UeElement type="text" property="cta_label" label="Label">
                        {x.cta.label}
                      </UeElement>
                    </Button>
                  )}
                </UeReference>
              </ComponentContainer>
            ))}
          </Row>
        ))}
      </ComponentsContainer>
    </>
  );
};
